<template>
  <div class="_w-max _pc-pad-y-75 _flex-col">
    <div class="animate__animated animate__bounceInUp"><AboutUs /></div>
    <div :class="scrollTop>500?'animate__animated animate__fadeInLeft':''"><Contact /></div>
    <div :class="scrollTop>1500?'animate__animated animate__fadeInRight':''"> <Message /></div>
  
  </div>
</template>

<script>
import AboutUs from "@/pages/about/components/AboutUs";
import Contact from "@/pages/about/components/Contact";
import Message from "@/pages/about/components/Message";
export default {
  name: "IndexAbout",
  components: { Message, Contact, AboutUs },
  data() {
    return {
      scrollTop:0,//页面滑动距离
    };
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods:{
    // 获取页面滚动距离
    handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    },
  }
};
</script>

<style scoped>
</style>
