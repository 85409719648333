<template>
  <div class="_w-max _pc-mar-y-45 _mb-pad-top-0 _mb-pad-bottom-50 _pc-pad-x-200 _mb-pad-x-30 _flex-col wow fadeInRight">
    <!--      title标题-->
    <div class="_pc-mar-bottom-35 _mb-mar-bottom-50 _flex-row-end">
      <div class="_pc-font-42 _mb-font-52 _b theme-color" style="line-height: 0.9;">MESSAGE</div><div class="_pc-font-28 _pc-pad-left-20 _mb-font-32 _mb-pad-left-25 _b">反馈与建议</div>
    </div>

    <div >
      <div class="_flex-row _mb-display-block">
        <div class="_pc-w-600 _mb-w-max">
          <div class="_flex-col-center _pc-font-16 _mb-font-28 _mb-mar-bottom-30">
            <span>您的称呼</span>
            <input v-model.trim="data.name" class="border_none _pc-pad-y-15 _pc-pad-x-15 _mb-pad-x-30 _mb-pad-y-30 _w-max _pc-mar-top-15 _mb-mar-top-30" type="text" placeholder="请留下您的称呼...">
            <div class="_pc-pad-y-15 _mb-pad-y-15"></div>
            <span>联系方式</span>
            <input v-model.trim="data.phone" class="border_none _pc-pad-y-15 _pc-pad-x-15 _mb-pad-x-30 _mb-pad-y-30 _w-max _pc-mar-top-15 _mb-mar-top-30" type="phone" placeholder="请留下您的联系方式...">
          </div>
        </div>
        <div class="_pc-mar-x-20 _pc-w-790 _mb-w-max _flex-col _pc-font-16 left-box _mb-font-28">
          <span>您的意见</span>
          <textarea v-model.trim="data.content" class="border_none _pc-pad-y-15 _mb-pad-y-30 _pc-pad-x-15 _mb-pad-x-30 _w-max _pc-mar-top-15 _mb-mar-top-30 _pc-h-155 _mb-h-270" type="text" placeholder="请留下您的宝贵意见..." style="resize: none;"></textarea>
        </div>
      </div>

      <button class="_mb-display-none _pc-mar-top-50 _mb-mar-top-30  msg-p  _pc-w-300 _pc-pad-y-10 _pc-pad-y-5 _flex-row-center-mid sub_msg _btn-p-hover _pc-mar-x-auto" @click="subMessage">
        <span class="_pc-font-16 _mb-font-28 _b">提交留言</span>
        <img class="_pc-w-20 _pc-mar-left-10 right-ico-02" src="@/assets/image/right-ico-03.png" alt=""/>
        <img class="_pc-w-20 _pc-mar-left-10 right-ico-hover" src="@/assets/image/right-ico-02.png" alt=""/>
      </button>

      <div class="_pc-display-none _mb-mar-top-30  _flex-row-center-mid _mb-w-max _white theme-color-bg _mb-pad-y-20" @click="subMessage">
        <span class=" _mb-font-28 _b">提交留言</span>
        <img class="_mb-w-30 _mb-mar-left-10" src="@/assets/image/right-ico-03.png" alt=""/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "Message",
  data(){
    return{
      data:{
        name:'',
        phone: '',
        content:''
      }
    }
  },
  methods:{
    async subMessage(){
      let that = this;
      let data = that.data;
      if(data.name){
      }else{alert('请填写名字'),that.checked = true;return}
      if(data.phone){
      }else{alert('请填写手机号码'),that.checked = true;return}
      if(data.content){
      }else{alert('请填写留言内容'),that.checked = true; return}
      await this.$axios.post('site/message_post',data).then(res=>{
        alert(res.data.msg);
        that.checked = true;
      })
      that.data = {
        name:'',
        phone: '',
        content:''
      };
    },
  }
}
</script>

<style scoped>

.border_none {
  border: none;
  border-radius: 3px;
  background-color:#f7f7f7;
}
.border_none:focus{
  outline:1px solid #999999;
}
.border_none:hover{
  /*border: 1px solid #ececec;*/
}
.sub_msg {
  border: 1px solid var(--theme-color-bg);
  color: #E21F19;
  background-color: #ffffff;
}
.sub_msg:hover {
  color: #f18380;
  background-color: #e7e7e7;
  cursor: pointer;
}
.left-box{
  position: relative;
}
.left-box:after{
  content: 'FEEDBACK';
  position: absolute;
  color: #aaaaaa;
  letter-spacing: 4px;
  top: -60px;
  right: 0;
}
.msg-p{color: var(--theme-color);}
._btn-p-hover:hover{background: var(--theme-color-bg); color:#FFF;}
._btn-p-hover{color: var(--theme-color-title);}
.right-ico-hover{display: block;}
.right-ico-02{display: none;}
._btn-p-hover:hover .right-ico-02{display: block;}
._btn-p-hover:hover .right-ico-hover{display: none;}


@media screen and (max-device-width: 768px) {
  .left-box:after{content: '';}
}
</style>
